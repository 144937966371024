import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse, ApiPaginatedResponse } from '~/api/types/Api'

export default class AgreementService extends ApiServiceBase {
  async list(query?: object | null): Promise<ApiPaginatedResponse<object>> {
    const params = query ?? {}
    // Ensure page parameter is properly handled if not provided
    if (!params.page) {
      params.page = 1
    }
    return await this.call('/v1/agreements', { params })
  }
  async accept(id: string, postData: object): Promise<ApiResponse<object>> {
    return await this.call(`/v1/agreements/${id}/accept`, postData)
  }
}
