
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexHincGRdIjpMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/agreements/index.vue?macro=true";
import { default as _91tab_939wjikLs8kGMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/[tab].vue?macro=true";
import { default as index5diXUvYNHnMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/[booking]/index.vue?macro=true";
import { default as indexsX8nOUB9mpMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/index.vue?macro=true";
import { default as indexqffeJtupcMMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/index.vue?macro=true";
import { default as indexkiVreqRP99Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/index.vue?macro=true";
import { default as indexd8UEeN8szIMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/[id]/index.vue?macro=true";
import { default as index5mSazoQ7HCMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/index.vue?macro=true";
import { default as formnnwJjQeKd5Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/new/form.vue?macro=true";
import { default as indexWduR8E6ivYMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/new/index.vue?macro=true";
import { default as blockedNaAMhYh6ZEMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/blocked.vue?macro=true";
import { default as indexEWc33owfsjMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/index.vue?macro=true";
import { default as indexXCNuDpHjiNMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/reset/index.vue?macro=true";
import { default as _91id_931ZhWEGybjJMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/[id].vue?macro=true";
import { default as indexCkima9EfqJMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/index.vue?macro=true";
import { default as _91id_930nOuRGz8JkMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/[id].vue?macro=true";
import { default as dealsrAJYnjWhpHMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/deals.vue?macro=true";
import { default as indexajcw8lKuFHMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/index.vue?macro=true";
import { default as indexvYvyJiv9kEMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payments/index.vue?macro=true";
import { default as _91id_93spNPCejyJdMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/[id].vue?macro=true";
import { default as enable_45disablemCN34326NnMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/enable-disable.vue?macro=true";
import { default as indexRTCAMRg8S4Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/index.vue?macro=true";
import { default as indexTdotZOhjjYMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[statementId]/[invoiceId]/index.vue?macro=true";
import { default as index9UIisLIoKbMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[statementId]/index.vue?macro=true";
import { default as index1E3v3ORhFZMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/index.vue?macro=true";
import { default as indexhcY2DHGDhPMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/index.vue?macro=true";
import { default as reinstatev6lOetxnCAMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/reinstate.vue?macro=true";
import { default as indexWjFI9wxMTzMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/verify/index.vue?macro=true";
import { default as indexZkg2Oediy5Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/reset-password/index.vue?macro=true";
import { default as _91id_93OZ6jhvQF9eMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/[id].vue?macro=true";
import { default as index8TylQKMgniMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/index.vue?macro=true";
import { default as newcVSW6RbYxhMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/new.vue?macro=true";
import { default as uploadZ2zlYRrN6ZMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/document/upload.vue?macro=true";
import { default as index_46clienteLUyvkKrasMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index8dy3E0SAdkMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/index.vue?macro=true";
import { default as update42yRb49InCMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/update.vue?macro=true";
export default [
  {
    name: "agreements",
    path: "/agreements",
    meta: indexHincGRdIjpMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/agreements/index.vue")
  },
  {
    name: "bookings-earnings-date-tab",
    path: "/bookings-earnings/:date()/:tab()",
    meta: _91tab_939wjikLs8kGMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/[tab].vue")
  },
  {
    name: "bookings-earnings-date-bookings-day-booking",
    path: "/bookings-earnings/:date()/bookings/:day()/:booking()",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/[booking]/index.vue")
  },
  {
    name: "bookings-earnings-date-bookings-day",
    path: "/bookings-earnings/:date()/bookings/:day()",
    meta: indexsX8nOUB9mpMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/index.vue")
  },
  {
    name: "bookings-earnings",
    path: "/bookings-earnings",
    meta: indexqffeJtupcMMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexkiVreqRP99Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/index.vue")
  },
  {
    name: "insure-id",
    path: "/insure/:id()",
    meta: indexd8UEeN8szIMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/[id]/index.vue")
  },
  {
    name: "insure",
    path: "/insure",
    meta: index5mSazoQ7HCMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/index.vue")
  },
  {
    name: "insure-new-form",
    path: "/insure/new/form",
    meta: formnnwJjQeKd5Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/new/form.vue")
  },
  {
    name: "insure-new",
    path: "/insure/new",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/insure/new/index.vue")
  },
  {
    name: "login-blocked",
    path: "/login/blocked",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/blocked.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexEWc33owfsjMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/index.vue")
  },
  {
    name: "login-reset",
    path: "/login/reset",
    meta: indexXCNuDpHjiNMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/reset/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: _91id_931ZhWEGybjJMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexCkima9EfqJMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/index.vue")
  },
  {
    name: "partner-plus*****-id",
    path: "/partner-plus*****/:id()",
    meta: _91id_930nOuRGz8JkMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/[id].vue")
  },
  {
    name: "partner-plus*****-deals",
    path: "/partner-plus*****/deals",
    meta: dealsrAJYnjWhpHMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/deals.vue")
  },
  {
    name: "partner-plus*****",
    path: "/partner-plus*****",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/index.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexvYvyJiv9kEMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payments/index.vue")
  },
  {
    name: "payouts-id",
    path: "/payouts/:id()",
    meta: _91id_93spNPCejyJdMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/[id].vue")
  },
  {
    name: "payouts-enable-disable",
    path: "/payouts/enable-disable",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/enable-disable.vue")
  },
  {
    name: "payouts",
    path: "/payouts",
    meta: indexRTCAMRg8S4Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/index.vue")
  },
  {
    name: "plans-invoices-statementId-invoiceId",
    path: "/plans-invoices/:statementId()/:invoiceId()",
    meta: indexTdotZOhjjYMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[statementId]/[invoiceId]/index.vue")
  },
  {
    name: "plans-invoices-statementId",
    path: "/plans-invoices/:statementId()",
    meta: index9UIisLIoKbMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[statementId]/index.vue")
  },
  {
    name: "plans-invoices",
    path: "/plans-invoices",
    meta: index1E3v3ORhFZMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexhcY2DHGDhPMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/index.vue")
  },
  {
    name: "register-reinstate",
    path: "/register/reinstate",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/reinstate.vue")
  },
  {
    name: "register-verify",
    path: "/register/verify",
    meta: indexWjFI9wxMTzMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/verify/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexZkg2Oediy5Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/reset-password/index.vue")
  },
  {
    name: "tickets-support-id",
    path: "/tickets-support/:id()",
    meta: _91id_93OZ6jhvQF9eMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/[id].vue")
  },
  {
    name: "tickets-support",
    path: "/tickets-support",
    meta: index8TylQKMgniMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/index.vue")
  },
  {
    name: "tickets-support-new",
    path: "/tickets-support/new",
    meta: newcVSW6RbYxhMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/new.vue")
  },
  {
    name: "vehicle-documents-document-upload",
    path: "/vehicle-documents/document/upload",
    meta: uploadZ2zlYRrN6ZMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/document/upload.vue")
  },
  {
    name: "vehicle-documents",
    path: "/vehicle-documents",
    meta: index_46clienteLUyvkKrasMeta || {},
    component: () => createClientPage(() => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/index.client.vue"))
  },
  {
    name: "vehicle-documents-vehicle",
    path: "/vehicle-documents/vehicle",
    meta: index8dy3E0SAdkMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/index.vue")
  },
  {
    name: "vehicle-documents-vehicle-update",
    path: "/vehicle-documents/vehicle/update",
    meta: update42yRb49InCMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/update.vue")
  }
]