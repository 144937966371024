import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (process.env.NODE_ENV !== 'production') return

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.sentryEnv,
    ignoreErrors: [
      /.*Fetch.*401.*|.*401.*Fetch.*/i,
      /.*Fetch.*419.*|.*419.*Fetch.*/i,
      /.*Fetch.*no response.*|.*no response.*Fetch.*/i,
      "Can't find variable: gmo",
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
})
