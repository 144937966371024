import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse, ApiPaginatedResponse } from '~/api/types/Api'

export default class BookingsService extends ApiServiceBase {
  async list(query?: object | null): Promise<ApiPaginatedResponse<object>> {
    const params = query ?? {}
    // Ensure page parameter is properly handled if not provided
    if (!params.page) {
      params.page = 1
    }
    return await this.call('/v1/bookings', params)
  }

  async get(id: string): Promise<ApiResponse<object>> {
    return await this.call(`/v1/bookings/${id}`)
  }
}
